//  Typography
//  -----------------------------

.text-justify {
  text-align: left;
  @include breakpoint(large) { 
    text-align: justify;
    text-justify: inter-word;
  }
}
// Adella - 400(Regular) 700(Bold) 900(Heavy)
// Proxi - 300(Light) 400(Regular) 700(Bold) 
.intro {
  font-size: 1.5rem;
  line-height: 1.4;
  margin: 0 0 1.4rem 0;
  //color: $light-gray;
  @include breakpoint(small only) { 
      font-size: 1.3rem;
  }
}
.newspaper {
  columns: 2;
  @include breakpoint(small only) { 
      columns: 1;
  }
}
h1,h2,h3,h4,h5 {
}
h1,.h1 {
  font-weight: 700;
  font-size:2.2rem;//3.64583rem;
  line-height: 1.2;
  @include breakpoint(small only) {
    font-size:1.6rem;
  }
}
h2,.h2 {
  font-weight: 400;
}
hr {
  //border-top: 1px solid #8c8b8b;
  border:none;
  text-align: center;
  margin:4.5rem auto 4.5rem auto;
} 